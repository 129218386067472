<template>
    <section v-if="secciones.length > 0" :class="clase">
        <div class="content_section">
            <div class="inner">
                <header class="content_header">
                    <h2>{{titulo}}</h2>
                </header>
                <article class="content_category" :class="getClaseSeccion(secion.id)" v-for="secion in secciones" :key="secion.id">
                    <div class="category_heading heading_link">
                        <h3>{{secion.name ? secion.name : secion.edition}}</h3>
                        <router-link :to="`/${secion.name ? `seccion/${secion.id}` : `old/${secion.edition}`}`" class="see_all"><span>Ver todo</span></router-link>
                    </div>
                    <obra :works="secion.extra ? secion.extras : secion.works" :categoria="secion" :old="old ? secion.edition : null"/>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
import Obra from '@/components/home/Obra.vue'
import axios from 'axios'

export default {
    name: "seccion",
    props: {
        titulo: String,
        profesional: Number,
        clase: String,
        extra: Number,
        old: Number
    },
    data: function() {
        return {
            secciones: []
        }
    },
    components:{
        Obra
    },
    created: function(){
        axios
            .get(process.env.VUE_APP_URL + 'categories', {
                params:{
                    edition: this.extra ? null : localStorage.getItem('edition'),
                    professional: this.profesional,
                    extra: this.extra,
                    old: this.old,
                    works: this.extra ? null : 1,
                    active: 1,
                    worksLimit: 5,
                    worksRandom: this.extra ? null : 1,
                    'orderBy[column]': this.extra ? 'actived_at' : null,
                    'orderBy[order]': this.extra ? 'desc' : null,
                }
            })
            .then(response => {
                let rs = response.data;
                if(rs.state == 'OK'){
                    this.secciones = rs.data;
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
    methods: {
        getClaseSeccion: function(id){
            switch(id){
                case 2:
                    return 'photo_category';
                case 3:
                    return 'story_category';
                case 4:
                    return 'story_category';
                case 5:
                    return 'music_category';
                case 6:
                    return 'audio_story_category';
                case 7:
                    return 'illustration_category';
                case 12:
                    return 'reel_category';
                default:
                case 1:
                case 8:
                case 9:
                case 10:
                    return 'video_category';
            }
        }
    }
}
</script>