<template>
    <header class="header clear header_change">
      <div class="inner">
        <div class="header_block left">
            <router-link to="/" class="logo ">
              <img src="@/assets/images/logo_white.png" alt="" class="logo_01">
              <img src="@/assets/images/logo.png" alt="" class="logo_02">
            </router-link>
            <h2 class="logo_claim">
              <span>World Mobile Uncensored Media Festival.</span>
              <span>Edición 2023/2024</span>
            </h2>
        </div>
        <div class="header_block right">
          <router-link to="/participa" class="participate_btn">
            <span class="badge">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0l1.33 1.893 1.775-1.484.796 2.173 2.099-.974.206 2.305 2.279-.398-.397 2.279 2.305.206-.975 2.099 2.173.795-1.484 1.775 1.893 1.331-1.893 1.331 1.484 1.775-2.173.795.975 2.099-2.305.206.397 2.279-2.279-.398-.206 2.305-2.099-.974-.796 2.173-1.775-1.484-1.33 1.893-1.331-1.893-1.775 1.484-.795-2.173-2.099.974-.206-2.305-2.279.398.398-2.279-2.305-.206.974-2.099-2.173-.795 1.484-1.775-1.893-1.331 1.893-1.331-1.484-1.775 2.173-.795-.974-2.099 2.305-.206-.398-2.279 2.279.398.206-2.305 2.099.974.795-2.173 1.775 1.484z"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0l1.33 1.893 1.775-1.484.796 2.173 2.099-.974.206 2.305 2.279-.398-.397 2.279 2.305.206-.975 2.099 2.173.795-1.484 1.775 1.893 1.331-1.893 1.331 1.484 1.775-2.173.795.975 2.099-2.305.206.397 2.279-2.279-.398-.206 2.305-2.099-.974-.796 2.173-1.775-1.484-1.33 1.893-1.331-1.893-1.775 1.484-.795-2.173-2.099.974-.206-2.305-2.279.398.398-2.279-2.305-.206.974-2.099-2.173-.795 1.484-1.775-1.893-1.331 1.893-1.331-1.484-1.775 2.173-.795-.974-2.099 2.305-.206-.398-2.279 2.279.398.206-2.305 2.099.974.795-2.173 1.775 1.484z"/></svg>
            </span>
            <span class="participate_btn_claim">¡Participa!<b>E inscríbete</b></span>
          </router-link>
        </div>
        <a href="javascript:void(0)" class="menu_trigger" :class="{close_btn: $parent.menuAbierto}" @click="$parent.menuAbierto = !$parent.menuAbierto">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
  </header>
</template>

<script>
export default {
    name: "jeader",
}
</script>