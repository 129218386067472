<template>
    <section class="video_home" style="z-index: 0;">
        <div class="video_home_wrapper">
            <div class="video_home_container">
                <div id="textoCabecera" class="video_home_intro">
                <h2>
                    <span>8<sup>a</sup> Edición</span>
                    <span>Premios</span>
                    <span>Bonobo</span>
                </h2>
                </div>
                <video id="videoCabecera" preload="none" autoplay="" muted="" loop="" style="top: 0%">
                    <source src="https://imgdb.premiosbonobo.com/fondo/OctavaEdicionSalida.mp4" type="video/mp4">
                </video>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "fondo",
    created () {
        window.addEventListener('scroll', this.victorApesta);
        this.ecuacion = -40 / window.innerHeight;
    },
    destroyed () {
        window.removeEventListener('scroll', this.victorApesta);
    },
    methods:{
        victorApesta(event){
            if(window.scrollY < window.innerHeight){
                document.querySelector('#textoCabecera').style.top = (window.scrollY*-0.5) + 'px';
                document.querySelector('#videoCabecera').style.top = this.ecuacion * window.scrollY + '%';
                document.querySelector('.header').classList.remove('header_change');
            }
            if(window.scrollY >= window.innerHeight){
                document.querySelector('.header').classList.add('header_change');
            }
        }
    },
    mounted: function(){
        this.victorApesta();
        document.querySelector('#videoCabecera').play();
    }
}
</script>