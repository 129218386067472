<template>
    <footer class="footer_wrapper">
      <div class="footer">
        <div class="inner">
          <div class="footer_content">
            <div class="footer_block">
              <p>© Premios Bonobo 2020 Es una marca de APEOGA. Todos los derechos reservados. Cualquier copia o reproducción, total o parcial de los contenidos de esta web, está totalmente prohibido sin consentimiento expreso. En virtud de lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, le informamos que los datos personales que usted nos facilite quedarán incorporados y serán tratados en los ficheros titularidad de APEOGA - Asociación de Productores y Editores de Obras y Grabaciones y Audiovisuales, con el fin de poder atender su petición y prestarle el servicio solicitado, así como para mantenerle informado, incluso por medios electrónicos, sobre cuestiones relativas a la actividad de la asociación y sus servicios.</p>
              <p>Asimismo, le informamos de la posibilidad de ejercer, en cualquier momento, los derechos de acceso, rectificación, cancelación y oposición de sus datos de carácter personal mediante escrito dirigido a APEOGA - Asociación de Productores y Editores de Obras y Grabaciones y Audiovisuales, Calle Hurtada, 31 Local, (28240) Hoyo de Manzanares, Madrid (Spain) o en el correo: premiosbonobo@apeoga.es. Diseño y hosting: Factoría Audiovisual</p>
              <p class="legal"><router-link to="/legal">Terminos legales</router-link></p>
              <p class="legal">APEOGA - Asociación de Productores y Editores de Obras y Grabaciones y Audiovisuales CIF: G84841899 , Calle Hurtada, 31 Local, (28240) Hoyo de Manzanares, Madrid (Spain).</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
    name: "pie"
}
</script>