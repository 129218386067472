<template>
    <div v-if="this.visible" class="photo_gallery_modal">
        <div @click="$emit('cerrar')" class="close_btn">CERRAR<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></div>
        <div v-if="this.hayFlechas()" @click="showNext(posFoto - 1)" class="modal_nav left_nav"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path></svg></div>
        <div class="modal_content tCenter">
            <img :src = "this.srcFoto">
            <a :href="this.getUrlDownload()" download="image.jpg" class="btn small graphic white right down mt20">Descargar imagen<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></a>
        </div>
        <div v-if="this.hayFlechas()" @click="showNext(posFoto + 1)" class="modal_nav right_nav"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg></div>

    </div>
</template>

<script>
export default {
    name: "ModalImagen",
    data: function(){
        return{
            posFoto: 0,
            srcFoto: false,
        }
    },
    props:{
        srcImg: {
            type: String,
            default: ""
        },
        visible: {
            type: Boolean,
            default: false
        },
        pos: {
            type: Number,
            default: 0
        },
        array: {
            type: Array
        }
    },
    watch:{
        array: function(){
            this.posFoto = this.pos;
            this.srcFoto = this.srcImg;
        },
    },
    methods:{
        getUrlDownload: function(){
            var name = this.srcImg.split("/")[this.srcImg.split("/").length - 1].split(".")[0];
            return process.env.VUE_APP_URL + "downloadimage/"+name;
        },
        hayFlechas: function(){
            return this.array.length > 0 && this.pos >= 0;
        },
        showNext: function(pos){
            if (pos < 0)
                this.posFoto = this.array.length - 1;
            else if(pos >= this.array.length)
                this.posFoto = 0;
            else
                this.posFoto = pos;
            this.srcFoto = this.array[this.posFoto];
        }

    }
}
</script>


<style>
    .photo_gallery_modal {background-color: rgba(0, 0, 0, 0.85);position: fixed;width: 100%;height: 100vh;z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .photo_gallery_modal .modal_content {padding: 40px 20px;}
    .photo_gallery_modal .modal_content img {display: block;max-height: 80vh;width: 100%;
        -webkit-box-shadow: 5px 5px 7px 0px rgba(0,0,0,0.48); 
        box-shadow: 5px 5px 7px 0px rgba(0,0,0,0.48);
    }
    .photo_gallery_modal .modal_nav {cursor: pointer;position: absolute;top: 0;bottom: 0;width: 80px;z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .photo_gallery_modal .modal_nav:hover {background-color: rgba(000, 000, 000, 0.4);}
    .photo_gallery_modal .modal_nav svg {fill: #5c5c5c;width: 40px;height: 40px;}
    .photo_gallery_modal .modal_nav:hover svg {fill: #fff;}
    .photo_gallery_modal .modal_nav.left_nav {left: 0;}
    .photo_gallery_modal .modal_nav.right_nav {right: 0;}
    .photo_gallery_modal .close_btn {color: #fff;cursor: pointer;font-weight: 700;line-height: 25px;position: absolute;top: 20px;right: 40px;z-index: 20;}
    .photo_gallery_modal .close_btn svg {fill: #fff;margin-left: 10px;vertical-align: top;}
</style>