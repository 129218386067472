<template>
	<section class="intro_main">
		<div class="content_section">
			<div class="inner">
				<header class="content_header">
					<h2>8<sup>ª</sup> Edición Premios Bonobo</h2>
				</header>
				<article class="content_category intro_features">

					<div class="intro_features_row">
						<div class="intro_features_row_item left item_text">
							<h3 class="feature_sub_heading">
								8<sup>ª</sup> Edición Premios Bonobo
							</h3>
							<h2 class="feature_heading">
								Fotografías de la gala de entrega de premios 8ª Edición Premios Bonobo
							</h2>
							<router-link to="/galeria" class="btn small graphic grey right"
								>Ver ahora<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path
										d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"
									/></svg
							></router-link>
						</div>
						<div class="intro_features_row_item left item_visual">
                            <router-link to="/galeria"
								><img src="@/assets/images/premios_bonobo_8a_edicion_fotos.jpg"
							/></router-link>
						</div>
					</div>

					<div class="intro_features_row">
						<div class="intro_features_row_item left item_visual">
							<router-link to="/extras/18"
								><img
									src="@/assets/images/premios_bonobo_8a_edicion_entrega.jpg"
							/></router-link>
						</div>
						<div class="intro_features_row_item left item_text">
							<h3 class="feature_sub_heading">
								8<sup>ª</sup> Edición Premios Bonobo
							</h3>
							<h2 class="feature_heading">
								Vídeo de la gala de entrega de premios 8<sup>ª</sup> Edición
								Premios Bonobo
							</h2>
							<router-link
								to="/extras/18"
								class="btn small graphic grey right mb20"
								>Ver ahora<svg
									xmlns="http://www.w3.org/2000/svg" 
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path
										d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"
									/></svg
							></router-link>
							<router-link
								to="/extras/19"
								class="btn small orange graphic grey right"
								>Ver Resumen ahora<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path
										d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"
									/></svg
							></router-link>
						</div>
					</div>

					<div class="intro_features_row">
						<div class="intro_features_row_item left item_text">
							<h3 class="feature_sub_heading">
								8<sup>ª</sup> Edición Premios Bonobo
							</h3>
							<h2 class="feature_heading">
								Presentación de la 8ª Edición Premios Bonobo
							</h2>
							<router-link to="/extras/17" class="btn small graphic grey right"
								>Ver ahora<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path
										d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"
									/></svg
							></router-link>
						</div>
						<div class="intro_features_row_item left item_visual">
							<router-link to="/extras/17"
								><img src="@/assets/images/foto_presentacion_premios_2024.jpg"
							/></router-link>
						</div>
					</div>

					<div class="grid grid_group grid_pad flex_center">
						<div class="grid_col grid_col_3_4">
							<!--<noticia />-->
						</div>
						<div class="grid_col grid_col_3_4">
							<div class="banner_block">
								<a
									href="https://shopohs.es/artistas/premios_bonobo"
									target="_blank"
									title="Ya puedes comprar los libros de los Premios Bonobo"
									class="banner_link banner_supermegabanner"
								>
									<img
										src="@/assets/images/libros_bonobos_980x90_std.jpg"
										alt="Ya puedes comprar los libros de los Premios Bonobo"
									/>
								</a>
							</div>
						</div>
					</div>
				</article>
			</div>
		</div>
	</section>
</template>

<script>
import Noticia from "@/components/home/Noticia.vue";

export default {
	name: "edicion",
	components: {
		Noticia,
	},
};
</script>
